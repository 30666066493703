import paymentRequestRouter from './payment_request';
import bulkRouter from './bulk';
import customerPaymentsRouter from './customer_payment';
import eCommerceRouter from './e_commerce';
import shopifyRouter from './shopify';
import eventRouter from './event';
import catalogueRouter from './catalogue';
import userRouter from './user';
import ticket from './ticket';
import generated_reports from './generated_reports';
import realEstateRouter from './real_estate';
import food_ordering from './food_ordering';
import invoicesRouter from './invoice'

export default {
  path: '',
  component: { template: '<router-view />' },
  meta: {
    requiresAuth: true,
    page_title: 'Dashboard',
  },
  children: [
    {
      path: 'dashboard',
      name: 'general-info',
      component: () =>
        import(
          /* webpackChunkName: "GeneralInfo" */ '../../views/dashboard/index/GeneralInfo.vue'
        ),
      meta: {
        requiresAuth: true,
        page_title: 'Dashboard',
      },
    },
    {
      path: 'search/express',
      name: 'express-search',
      meta: {
        page_title: 'Express Search',
      },
      component: () =>
        import(
          /* webpackChunkName: "ExpressSearchTransactions" */ '../../views/dashboard/express_search/TransactionList'
        ),
    },
    {
      path: 'transactions',
      name: 'all-transactions',
      component: () =>
        import(
          /* webpackChunkName: "AllTransactions" */ '../../views/dashboard/index/Transactions'
        ),
      meta: {
        plugin: 'Dashboard',
        requiresAuth: true,
        page_title: 'All transactions',
      },
    },
    {
      path: 'transactions/:id',
      name: 'all-transactions_details',
      component: () =>
        import(
          /* webpackChunkName: "AllTransactions" */ '../../views/dashboard/index/TransactionDetails'
        ),
    },
    paymentRequestRouter,
    bulkRouter,
    customerPaymentsRouter,
    eCommerceRouter,
    catalogueRouter,
    userRouter,
    shopifyRouter,
    eventRouter,
    realEstateRouter,
    invoicesRouter,
    ticket,
    food_ordering,
    generated_reports,
    {
      path: 'help',
      name: 'help-support',
      component: () =>
        import(
          /* webpackChunkName: "HelpSupport" */ '../../views/dashboard/HelpSupport.vue'
        ),
      meta: {
        requiresAuth: true,
        page_title: 'Help/Support',
        breadcrumb_items: ['general-info', 'help-support'],
      },
    },
  ],
};
