import { i18n } from '../../plugins/i18n';

export default {
  path: 'shopify',
  component: {
    template: `<router-view></router-view>`,
  },
  name: 'shopify',
  meta: {
    page_title: 'Shopify',
    is_wrapper: true,
  },
  children: [
    {
      path: 'transactions',
      name: 'shopify-transactions',
      component: () =>
        import(
          /* webpackChunkName: "Shopify" */ '../../views/dashboard/shopify/Transactions'
        ),
      meta: {
        page_title: i18n.tc('Transactions'),
        plugin: 'shopify',
        breadcrumb_items: ['general-info', 'shopify', 'shopify-transactions'],
      },
    },
    {
      path: 'transactions/:id',
      name: 'shopify-transactions-details',
      component: () =>
        import(
          /* webpackChunkName: "Shopify" */ '../../views/dashboard/shopify/TransactionDetails'
        ),
      meta: {
        plugin: 'shopify',
      },
    },
    {
      path: 'deleted-transactions',
      name: 'shopify-deleted',
      component: () =>
        import(
          /* webpackChunkName: "Shopify" */ '../../views/dashboard/shopify/DeletedTransactions'
        ),
      meta: {
        page_title: i18n.tc('Deleted Paid Transactions'),
        breadcrumb_items: ['general-info', 'shopify', 'shopify-deleted'],
        plugin: 'shopify',
      },
    },
    {
      path: 'deleted-transactions/:id',
      name: 'shopify-deleted-details',
      component: () =>
        import(
          /* webpackChunkName: "Shopify" */ '../../views/dashboard/shopify/DeletedTransactionDetails'
        ),
    },
    {
      path: 'table-headers',
      name: 'shopify-table-headers',
      component: () =>
        import(
          /* webpackChunkName: "TableHeaders" */ '../../views/dashboard/shopify/_settings/TableHeaders.js'
        ),
      meta: {
        page_title: i18n.tc('Table Headers'),
        breadcrumb_items: ['general-info', 'shopify', 'table-headers'],
      },
    },
  ],
};
