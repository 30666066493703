import axios from 'axios';
import Vue from 'vue';
import { i18n } from '../plugins/i18n';

export const API_ORIGIN = localStorage.getItem('VUE_APP_CORE_SERVER_ORIGIN');
export const BASE_API_URL = API_ORIGIN + '/api/v1';
export const BASE_CHECKOUT_API = API_ORIGIN + '/checkout/v1';
export const BASE_INVOICE_API = API_ORIGIN + '/invoice/v1';

// Authorization header is inserted automatically to each request (if token is present)
export const authInterceptor = [
  (config) => {
    if (Vue.prototype.$token()) {
      config.headers['Accept-language'] = i18n.locale;
      config.headers.Authorization = `Bearer ${Vue.prototype.$token()}`;
    }

    delete config.headers.noAuthRequired;

    return config;
  },
  (error) => Promise.reject(error),
];

export const Service = (url) =>
  axios.create({
    baseURL: BASE_API_URL + url,
  });

export const AuthorizedCheckoutService = (url) => {
  const checkoutService = axios.create({
    baseURL: BASE_CHECKOUT_API + url,
  });
  checkoutService.interceptors.request.use(...authInterceptor);
  return checkoutService;
};

export const AuthorizedInvoiceService = (url) => {
  const invoiceService = axios.create({
    baseURL: BASE_INVOICE_API + url,
  });
  invoiceService.interceptors.request.use(...authInterceptor);

  return invoiceService;
};

export const AuthorizedService = (url) => {
  const authorizedService = Service(url);
  authorizedService.interceptors.request.use(...authInterceptor);
  return authorizedService;
};
