export default {
  path: 'spf',
  component: { template: '<router-view></router-view>' },
  name: 'shopify',
  children: [
    {
      path: 'payment-options/:transaction_id',
      name: 'shopify-gateway-select',
      component: () =>
        import(
          /* webpackChunkName: "ShopifyPay" */ '../../views/customer/pos/ShopifyPay'
        ),
      meta: {
        plugin: 'shopify',
      },
    },
    {
      path: 'payment-details/:attempt_id',
      name: 'shopify-attempt-details',
      component: () =>
        import(
          /* webpackChunkName: "AttemptDetails" */ '../../views/customer/pos/AttemptDetails.vue'
        ),
      meta: {
        plugin: 'shopify',
      },
    },
  ],
};
