import Vue from 'vue';
import Router from 'vue-router';
import { i18n } from '../plugins/i18n';

import { isLoggedIn } from '../lib/auth';
import dashboardRouter from './dashboard';
import posRouter from './customer/pos';
import shopifyRouter from './customer/shopify';
import catalogueRouter from './customer/catalogue';
import codePairingRouter from './customer/code_pairing';
import eventsRouter from './customer/events';

const ws_url = Vue.observable({ ws_url: {} });
Object.defineProperty(Vue.prototype, '$ws_url', {
  get() {
    return ws_url.ws_url;
  },
  set(value) {
    ws_url.ws_url = value;
  },
});

Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:lang',
      component: { template: '<router-view />' },
      children: [
        {
          path: 'search/express',
          name: 'express-search',
          meta: {
            page_title: 'Express Search',
          },
          component: () =>
            import(
              /* webpackChunkName: "ExpressSearchList" */ '../views/dashboard/express_search/TransactionList'
            ),
        },
        dashboardRouter,
        posRouter,
        catalogueRouter,
        codePairingRouter,
        shopifyRouter,
        eventsRouter,
      ],
    },
    {
      path: '/:lang/login',
      name: 'login',
      component: () =>
        import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    },
    {
      path: '/:lang/forget-password',
      name: 'forget-password',
      component: () =>
        import(
          /* webpackChunkName: "ForgotPassword" */ '../views/ForgetPassword.vue'
        ),
    },
    {
      path: '*',
      name: '404',
      component: () =>
        import(/* webpackChunkName: "404" */ '../views/errors/NotFound.vue'),
    },
  ],
});

// router.beforeEach((to, from, next) => {
//     // https://github.com/vuejs/vue-router/issues/906
//     // https://github.com/vuejs/vue-router/pull/917
//     // https://github.com/rayfranco/bourgeon/issues/2
//     // https://medium.com/hypefactors/add-i18n-and-manage-translations-of-a-vue-js-powered-website-73b4511ca69c
//     // https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js

//     // Logic to redirect to language-prefixed route ::::::::::
//     const possible_path = '/en' + to.fullPath;
//     const resolved = router.resolve(possible_path);
//     console.log(possible_path,'---',resolved)
//     if (resolved.route && resolved.route.name && resolved.route.name !== '404') {
//         return next(possible_path)
//     }
//     // :::::::::::::::::::::::::::::::::::::::::::::::::::::::

//     // i18n logic ::::::::::::::::::::::::::::::::::::::::::::
//     const lang = to.params.lang;
//     if (lang === 'ar' && !(i18n.loaded_ar_message || i18n.loading_ar_translations)) {
//         // we need to load translations
//         i18n.loading_ar_translations = true;
//         loadTranslations()
//             .then(({ data }) => {
//                 i18n.setLocaleMessage('ar', data);
//                 i18n.loaded_ar_message = true;
//             })
//             .catch(err => {
//                 console.error('failed to load translations', err)
//             })
//             .finally(() => {
//                 i18n.loading_ar_translations = false;
//             })
//     }
//     if (i18n.locale !== lang) {
//         i18n.locale = lang
//     }
//     // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::

//   return next();
//   // ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// });

router.beforeEach((to, from, next) => {
  // i18n logic ::::::::::::::::::::::::::::::::::::::::::::
  const lang = to.params.lang;
  if (i18n.locale !== lang) {
    i18n.locale = lang;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn()) {
      return next({
        name: 'login',
        params: { lang: to.params.lang || 'en' },
      });
    }
  }
  return next();
});
export default router;
